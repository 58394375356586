#root {
    width: 100%;
    height: 100%;
}

.ant-card {
    margin-bottom: 8px;
}

.ant-descriptions {
    margin-bottom: 8px;
}

.ant-btn {
    min-width: 80px;
}
